<template>
  <section id="meeting">
    <v-row v-if="user.zoom_key && user.zoom_secret">
      <v-col cols="12" xl="9" lg="10">
        <div class="text-h5 ml-2 mb-5">Manage Meetings</div>
        <v-sheet max-width="500" color="transparent">
          <v-item-group mandatory>
            <v-row>
              <v-col cols="12" md="6">
                <v-item>
                  <v-card
                    :color="
                      $route.name === 'Admin Meeting Lists' ? 'success' : '#fff'
                    "
                    class="d-flex align-center"
                    :dark="$route.name === 'Admin Meeting Lists'"
                    height="100"
                    :to="{ name: 'Admin Meeting Lists' }"
                    exact
                  >
                    <v-scroll-y-transition>
                      <div class="flex-grow-1 text-center">
                        <v-icon
                          size="50"
                          :color="
                            $route.name === 'Admin Meeting Lists'
                              ? ''
                              : 'success'
                          "
                          >mdi-timeline-clock</v-icon
                        >
                        <div
                          class="caption font-weight-bold"
                          :class="
                            $route.name === 'Admin Meeting Lists'
                              ? ''
                              : 'success--text'
                          "
                        >
                          UP COMING MEETINGS
                        </div>
                      </div>
                    </v-scroll-y-transition>
                  </v-card>
                </v-item>
              </v-col>
              <v-col cols="12" md="4">
                <v-item>
                  <v-card
                    :color="
                      $route.name === 'Admin Create Meeting'
                        ? 'success'
                        : '#fff'
                    "
                    class="d-flex align-center"
                    :dark="$route.name === 'Admin Create Meeting'"
                    height="100"
                    :to="{ name: 'Admin Create Meeting' }"
                    exact
                  >
                    <v-scroll-y-transition>
                      <div class="flex-grow-1 text-center">
                        <v-icon
                          size="50"
                          :color="
                            $route.name === 'Admin Create Meeting'
                              ? ''
                              : 'success'
                          "
                          >mdi-video-plus</v-icon
                        >
                        <div
                          class="caption font-weight-bold"
                          :class="
                            $route.name === 'Admin Create Meeting'
                              ? ''
                              : 'success--text'
                          "
                        >
                          SCHEDULE
                        </div>
                      </div>
                    </v-scroll-y-transition>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-sheet>

        <router-view />
      </v-col>
    </v-row>
    <div v-else>
      <h2 class="font-weight-thin">No zoom credentials found</h2>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapState("admin", {
      user: (state) => state.user,
    }),
  },
  created() {
    this.getMeetings();
  },
  methods: {
    ...mapActions("admin", ["getMeetings"]),
  },
};
</script>