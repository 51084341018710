var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"meeting"}},[(_vm.user.zoom_key && _vm.user.zoom_secret)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"9","lg":"10"}},[_c('div',{staticClass:"text-h5 ml-2 mb-5"},[_vm._v("Manage Meetings")]),_c('v-sheet',{attrs:{"max-width":"500","color":"transparent"}},[_c('v-item-group',{attrs:{"mandatory":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-item',[_c('v-card',{staticClass:"d-flex align-center",attrs:{"color":_vm.$route.name === 'Admin Meeting Lists' ? 'success' : '#fff',"dark":_vm.$route.name === 'Admin Meeting Lists',"height":"100","to":{ name: 'Admin Meeting Lists' },"exact":""}},[_c('v-scroll-y-transition',[_c('div',{staticClass:"flex-grow-1 text-center"},[_c('v-icon',{attrs:{"size":"50","color":_vm.$route.name === 'Admin Meeting Lists'
                            ? ''
                            : 'success'}},[_vm._v("mdi-timeline-clock")]),_c('div',{staticClass:"caption font-weight-bold",class:_vm.$route.name === 'Admin Meeting Lists'
                            ? ''
                            : 'success--text'},[_vm._v(" UP COMING MEETINGS ")])],1)])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-item',[_c('v-card',{staticClass:"d-flex align-center",attrs:{"color":_vm.$route.name === 'Admin Create Meeting'
                      ? 'success'
                      : '#fff',"dark":_vm.$route.name === 'Admin Create Meeting',"height":"100","to":{ name: 'Admin Create Meeting' },"exact":""}},[_c('v-scroll-y-transition',[_c('div',{staticClass:"flex-grow-1 text-center"},[_c('v-icon',{attrs:{"size":"50","color":_vm.$route.name === 'Admin Create Meeting'
                            ? ''
                            : 'success'}},[_vm._v("mdi-video-plus")]),_c('div',{staticClass:"caption font-weight-bold",class:_vm.$route.name === 'Admin Create Meeting'
                            ? ''
                            : 'success--text'},[_vm._v(" SCHEDULE ")])],1)])],1)],1)],1)],1)],1)],1),_c('router-view')],1)],1):_c('div',[_c('h2',{staticClass:"font-weight-thin"},[_vm._v("No zoom credentials found")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }